<template>
  <div class="row">
    <div class="col-12">
      <el-table refs="auditlogTable" row-key="id" v-loading="loading"
        header-row-class-name="thead-light" header-cell-class-name="text-center" style="width: 100%"
        :data="tableData" stripe>
        <el-table-column prop="created_at" label="时间" width="160px"></el-table-column>
        <el-table-column prop="username" label="操作者" width="140px"></el-table-column>
        <el-table-column prop="values" label="数据"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objectName: {
      type: String
    },
    objectId: {
        type: Number
    }
  },
  data() {
    return {
      loading: false,
      tableData: []
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.axios.get("auditlogs", { params: { object_name: this.objectName, object_id: this.objectId } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        this.$notifyErr('未能成功加载数据', e.message)
        this.loading = false
      })
    }
  },
  mounted() {
    this.fetchData()
  }
};
</script>
